<template>
    <div class="container">
        <el-form :model="dataForm" ref="dataForm" label-position="left" label-width="160px">
            <el-form-item label="反馈结果自动审核">
                <el-switch
                    v-model="dataForm.result_auto_audit"
                    active-text="开启自动审核(系统审核)"
                    inactive-text="关闭自动审核(人工审核)"
                    :active-value="true"
                    :inactive-value="false">
                </el-switch>
            </el-form-item>
            <el-form-item label="达人加盟费用(元)">
                <el-input type="number" v-model="dataForm.join_price" placeholder="达人加盟支付费用，单元：元" clearable/>
            </el-form-item>
            <el-form-item label="提现起提金额(元)">
                <el-input type="number" v-model="dataForm.limit_withdrawal" placeholder="佣金提现提起金额，单元：元" clearable/>
            </el-form-item>
            <el-form-item label="分享转发奖励金额(元)">
                <el-input type="number" v-model="dataForm.unit_commission" placeholder="视频分享转发奖励金额，单元：元" clearable/>
            </el-form-item>
            <el-form-item label="站点佣金起提金额(元)">
                <el-input type="number" v-model="dataForm.site_limit_withdrawal" placeholder="站点邀请佣金最少提现金额，单元：元"
                          clearable/>
            </el-form-item>
            <el-form-item>
                <el-button v-if="$perm('league_PlatformSetting_edit')" @click="doSave" :loading="loading"
                           type="primary">保 存
                    配 置
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: "Setting",
    data: function () {
        return {
            loading: false,
            dataForm: {
                result_auto_audit: true,
                join_price: 99,
                limit_withdrawal: 10,
                unit_commission: 1,
                site_limit_withdrawal: 0,
                site_withdrawal_commission: 0,
            }
        }
    },
    activated: function () {
        this.getConfig()
    },
    methods: {
        getConfig: function () {
            this.$helper._get(this, 'league.systemSetting.config', {}, resp => {
                this.dataForm = resp.data
            })
        },
        doSave: function () {
            this.$helper._post(this, 'league.systemSetting.update', {setting: JSON.stringify(this.dataForm)}, resp => {
                this.$message.success(resp.message)
            })
        }
    }
}
</script>

<style scoped>

</style>
